import * as React from "react";
import ArticleLayout from "../articles/article-layout";
import copyList from '../../components/jose/copyList';
import References from "../global/references.js";

export const Content = (props) => {
  return (
    <div className={`${props.show ? 'd-block' : 'd-none'}`}>
      <ArticleLayout
        copyList={ copyList }
        page="jose"
        ariaLabel="Two people, Jose and Joaquin looking at each other"
        videoStillAlt="Group of friends touching glasses in a toast"
        subheading="OVERCOMING BARRIERS TO PRE-EXPOSURE PROPHYLAXIS (PrEP) MEDICINE USE IN HISPANIC/LATINO COMMUNITIES IN THE AMERICAN SOUTH"
        noUpper={ true }
        heading="A Conversation With North Carolina Advocates José Romero &amp; Joaquín CarcaÑo"
        publishedDate='March 25, 2022'
        minuteRead='5'
      />
      <References page={props.page} />
    </div>
  )
}