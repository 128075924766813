import * as React from "react";
import { Component } from 'react';
import Layout from '../../components/global/layout'
import { Content } from '../../components/jose/content';


class Jose extends Component {
  render () {
  return (
    <>
      <Layout section="articles" page="jose">
        <Content section="articles" page="jose" />
      </Layout>
    </>
  )
  }
}

export default Jose;